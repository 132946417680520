const ItemStateDetails = {
  NEW: {
    key: 'new',
    description: 'New',
    tooltip: "New Status. Items haven't been auto slotted.",
    className: 'state pending'
  },
  PENDING1: {
    key: 'pending1',
    description: 'Pending1',
    tooltip: 'Only the "Business Center" is determined. "Item group" and "Attribute group" should be determined.',
    className: 'state pending'
  },
  PENDING2: {
    key: 'pending2',
    description: 'Pending2',
    tooltip: 'Only the "Business Center" and the "Item Group" are determined! "Attribute Group" should be determined.',
    className: 'state pending'
  },
  AUTOSLOTTED: {
    key: 'autoslotted',
    description: 'Auto Slotted',
    tooltip: 'Auto Slotted items that need manual verification.',
    className: 'state partial'
  },
  COMPLETED: {
    key: 'approved',
    description: 'Completed',
    tooltip: 'Manually approved items.',
    className: 'state approved'
  },
  AUTOAPPROVED: {
    key: 'autoapproved',
    description: 'Auto Apprvd',
    tooltip:
      'Auto Slotted and auto approved items that had a sufficiant confidence score in the auto-slotting process.',
    className: 'state autoapproved'
  }
};

export default ItemStateDetails;
