import { action } from 'reduxHelpers';
import {
  USER,
  TAXONOMY,
  HIERARCHY,
  ITEMUPDATE,
  ITEMSAVE,
  ITEMSDETAILS,
  ITEMSDETAILSCLOSE,
  ITEMREVERT,
  BULKCHANGE
} from './actionTypes';

const loadUserDetails = () => action(USER.REQUEST);
const loadItemDetails = ({ bc, ig, ag, page, search, range, isSyscoBrand, itemStatus }) =>
  action(TAXONOMY.REQUEST, { bc, ig, ag, page, search, range, isSyscoBrand, itemStatus });
const loadTaxonomyHierarchy = () => action(HIERARCHY.REQUEST, {});
const updateItemSlotting = (item, selectType, newValue, loadedItems) =>
  action(ITEMUPDATE.REQUEST, { item, selectType, newValue, loadedItems });
const saveSlotting = item => action(ITEMSAVE.REQUEST, { item });
const itemDetailsPopUp = stepId => action(ITEMSDETAILS.REQUEST, { stepId });
const removeItemDetails = () => action(ITEMSDETAILSCLOSE.REQUEST, {});
const revertItem = item => action(ITEMREVERT.REQUEST, item);
const bulkPropChange = ({ bc, ig, ag, supcs }) => action(BULKCHANGE.REQUEST, { bc, ig, ag, supcs });
const bulkLockOn = () => action(BULKCHANGE.SUCCESS);
const bulkLockOff = () => action(BULKCHANGE.FAILURE);

export {
  loadUserDetails,
  loadItemDetails,
  loadTaxonomyHierarchy,
  updateItemSlotting,
  saveSlotting,
  itemDetailsPopUp,
  removeItemDetails,
  revertItem,
  bulkPropChange,
  bulkLockOn,
  bulkLockOff
};
