import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';

export function ItemActions({ item, type, actions }) {
  const saveSlotting = () => {
    actions.saveSlotting(item);
  };

  let caption = 'SAVE';
  if (item.bc && item.ig && item.ag) {
    caption = 'APPROVE';
    if (item.status === 8) {
      caption = 'UPDATE';
    }
  }

  return (
    <React.Fragment>
      {type === 'APPROVEPENDING' && (
        <Button type="primary" className="btn green-action-btn in-grid" onClick={saveSlotting}>
          {caption}
        </Button>
      )}
    </React.Fragment>
  );
}

export default withTranslation()(ItemActions);

ItemActions.propTypes = {
  type: PropTypes.string,
  t: PropTypes.func
};
