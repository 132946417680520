import React from 'react';
import { Button } from 'antd';
import BulkHierarchySelect from './BulkHierarchySelect';

class BulkSlotter extends React.Component {
  render() {
    let {
      bcs,
      bulkSupcs,
      bulkBc,
      bulkIg,
      bulkAg,
      onBulkBcChange,
      onBulkIgChange,
      onBulkAgChange,
      actions,
      bulkLock
    } = this.props;

    let bulkSelectedBc = bcs.find(element => element.id === bulkBc);
    let bulkIgs = bulkSelectedBc ? bulkSelectedBc.igs : [];
    let bulkSelectedIg = bulkIgs.find(element => element.id === bulkIg);
    let bulkAgs = bulkSelectedIg ? bulkSelectedIg.ags : [];

    bulkBc = bulkBc || null;
    bulkIg = bulkIg || null;
    bulkAg = bulkAg || null;

    const updateOneItem = item => {
      actions.saveSlotting(item);
    };

    const onBulkSlot = () => {
      actions.bulkLockOn();
      for (let i = 0; i < bulkSupcs.length; i++) {
        setTimeout(
          () =>
            updateOneItem({
              supc: bulkSupcs[i],
              status: 8,
              bc: bulkBc,
              ig: bulkIg,
              ag: bulkAg
            }),
          i * 5000
        );
      }
      setTimeout(actions.bulkLockOff, bulkSupcs.length * 5000);
    };

    return (
      <div className="bulk-slotter" style={{ display: bulkSupcs.length > 0 ? 'flex' : 'none' }}>
        <div className="bulk-count">
          {bulkSupcs.length + (bulkSupcs.length === 1 ? ' Item' : ' Items') + ' selected'}
        </div>
        <BulkHierarchySelect
          firstValue={'Business Center'}
          selectedOption={bulkBc}
          onChangeMethod={onBulkBcChange}
          options={bcs}
        />
        {bulkBc && (
          <BulkHierarchySelect
            firstValue={'Item Group'}
            selectedOption={bulkIg}
            onChangeMethod={onBulkIgChange}
            options={bulkIgs}
          />
        )}
        {bulkIg && (
          <BulkHierarchySelect
            firstValue={'Attribute Group'}
            selectedOption={bulkAg}
            onChangeMethod={onBulkAgChange}
            options={bulkAgs}
          />
        )}
        <Button disabled={!bulkAg} type="primary" className="btn bulk-slot" onClick={onBulkSlot} loading={bulkLock}>
          BULK SLOT
        </Button>
        <div className="bulk-inprogress-msg" style={{ display: bulkLock ? 'block' : 'none' }}>
          In Progress...
        </div>
      </div>
    );
  }
}

export default BulkSlotter;
