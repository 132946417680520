import { combineReducers } from 'redux';
import initialState from 'store/initialState';
import { cloneDeep, remove, uniq } from 'lodash';

import {
  USER,
  TAXONOMY,
  ITEMSCOUNT,
  HIERARCHY,
  ITEMUPDATE,
  ITEMSAVE,
  ITEMSDETAILS,
  ITEMSDETAILSCLOSE,
  UNSAVEDSUPCS,
  UPDATELOADED,
  ITEMREVERT,
  BULKCHANGE
} from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const user = createReducer(USER, initialState.user);

const getItemsReverted = (state, action) => {
  let items = [...state.data],
    itemIndex = -1;
  items.forEach((item, index) => {
    if (item.supc === action.item.supc) {
      itemIndex = index;
    }
  });
  if (itemIndex >= 0) {
    action.loadedItems.forEach(loadedItem => {
      if (loadedItem.supc === action.item.supc) {
        items[itemIndex] = { ...items[itemIndex], bc: loadedItem.bc, ig: loadedItem.ig, ag: loadedItem.ag };
      }
    });
  }
  return [...items];
};

const items = (state = initialState.items, action) => {
  switch (action.type) {
    case TAXONOMY.REQUEST:
      return { ...state, fetching: true, error: null };
    case TAXONOMY.SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload.data,
        page: action.payload.page,
        bc: action.payload.bc,
        ig: action.payload.ig,
        ag: action.payload.ag,
        search: action.payload.search,
        range: action.payload.range,
        isSyscoBrand: action.payload.isSyscoBrand,
        itemStatus: action.payload.itemStatus
      };
    case ITEMSCOUNT.REQUEST:
      return { ...state, error: null };
    case ITEMSCOUNT.SUCCESS:
      return { ...state, error: null, recordCount: action.payload };
    case ITEMUPDATE.SUCCESS:
      let items = [...state.data];
      items.forEach((item, index) => {
        if (item.supc === action.item.supc) {
          items[index] = { ...items[index], ...action.item };
        }
      });
      return { ...state, data: items };
    case ITEMREVERT.REQUEST:
      return { ...state, data: getItemsReverted(state, action) };
    default:
      return { ...state };
  }
};

const loadedItems = (state = initialState.loadedItems, action) => {
  switch (action.type) {
    case TAXONOMY.SUCCESS:
      return {
        ...state,
        data: cloneDeep(action.payload.data),
        unsavedSupcs: []
      };
    case UNSAVEDSUPCS.SUCCESS:
      let unSavedSupcs = [...state.unsavedSupcs];
      if (action.isUnSaved) {
        unSavedSupcs.push(action.supc);
        unSavedSupcs = uniq(unSavedSupcs);
      } else {
        remove(unSavedSupcs, unSupc => {
          return action.supc === unSupc;
        });
      }
      return { ...state, unsavedSupcs: unSavedSupcs };
    case UPDATELOADED.SUCCESS:
      let data = [...state.data];
      data.forEach((item, index) => {
        if (item.supc === action.item.supc) {
          data[index] = action.item;
        }
      });
      return { ...state, data: data };
    default:
      return { ...state };
  }
};

const detailsCard = (state = initialState.detailsCard, action) => {
  switch (action.type) {
    case ITEMSDETAILS.REQUEST:
      return { ...state, loading: true, data: null };
    case ITEMSDETAILSCLOSE.REQUEST:
      return { ...state, loading: false, data: null };
    case ITEMSDETAILS.SUCCESS:
      return { ...state, loading: false, data: action.payload };
    default:
      return { ...state };
  }
};

const currentItem = (state = {}, action) => {
  switch (action.type) {
    case ITEMSAVE.REQUEST:
      return { ...state, fetching: true, error: null };
    default:
      return { ...state };
  }
};

const taxonomy = (state = initialState.taxonomy, action) => {
  switch (action.type) {
    case HIERARCHY.REQUEST:
      return { ...state, fetching: false, error: null };
    case HIERARCHY.SUCCESS:
      return { ...state, fetching: false, bcs: action.payload };
    default:
      return { ...state };
  }
};

const bulk = (state = initialState.bulk, action) => {
  switch (action.type) {
    case BULKCHANGE.REQUEST:
      return { ...state, ...{ bc: action.bc, ig: action.ig, ag: action.ag, supcs: action.supcs } };
    case BULKCHANGE.SUCCESS:
      return { ...state, ...{ lock: true } };
    case BULKCHANGE.FAILURE:
      return { ...state, ...{ lock: false } };
    case TAXONOMY.REQUEST:
      return { ...state, ...initialState.bulk };
    default:
      return { ...state };
  }
};

export default combineReducers({
  currentItem,
  user,
  items,
  loadedItems,
  taxonomy,
  detailsCard,
  bulk
});
