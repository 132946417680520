import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { setAuth } from 'Authenticator';
import App from 'App';

// import { start } from 'single-spa';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  parcelCanUpdate: true
});

// function domElementGetter() {
//   return document.getElementById('manualslotting-page');
// }

export const bootstrap = [reactLifecycles.bootstrap];

export async function mount(props) {
  const { Auth } = props;

  setAuth(Auth);

  console.log('console log vendor', props); // do something with the common authToken in app1
  return reactLifecycles.mount(props);
}

export const unmount = [reactLifecycles.unmount];
export const update = [reactLifecycles.update];

console.info(`Manualslotting-UI-released on:${process.env.REACT_APP_BUILD_TIME}`);

/**
 * start();
 *
 * ReactDOM.render(<App />, document.getElementById('manualslotting-page'));
 */
