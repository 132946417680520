import React from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import i18n from './_i18n';
import MainApp from './MainApp';

function App(props) {
  const { language } = props;
  i18n.changeLanguage(language);
  const selectedVendor = 12345;
  return (
    <Provider store={store}>
      <MainApp selectedVendor={selectedVendor} />
    </Provider>
  );
}

export default App;
