const ConfidenceStateDetails = {
  GOOD: {
    key: 'good',
    description: 'Good',
    tooltip: "Auto Slotting confidence score is good. It's above 95%.",
    className: 'confidence-state good'
  },
  SATISFACTORY: {
    key: 'satisfactory',
    description: 'Satisfactory',
    tooltip: "Auto Slotting confidence score is satisfactory. It's above 85%.",
    className: 'confidence-state satisfactory'
  },
  BAD: {
    key: 'bad',
    description: 'Bad',
    tooltip: "Auto Slotting confidence score is not satisfactory. It's below 85%.",
    className: 'confidence-state bad'
  },
  MODIFIED: {
    key: 'modified',
    description: 'Modified',
    tooltip: 'Item Manually has been modified after being auto-slotted.',
    className: 'confidence-state modified'
  },
  MANUAL: {
    key: 'manual',
    description: '',
    tooltip: 'This item is not auto slotted.',
    className: 'confidence-state modified'
  },
  AUTO: {
    key: 'auto',
    description: 'Auto',
    tooltip: 'This item is auto slotted. But no confidence score found.',
    className: 'confidence-state modified'
  }
};

export default ConfidenceStateDetails;
