import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import { withTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';
import AppLoader from 'components/AppLoader';
import * as batchesActions from 'actions';
import { Table, DatePicker, Select, Pagination, Checkbox, Tooltip } from 'antd';
import ItemStateTag from '../components/ItemStateTag';
import AnchorTag from '../components/AnchorTag';
import ConfidenceStateTag from '../components/ConfidenceStateTag';
import ItemActions from '../components/ItemActions';
import EditableInput from '../components/EditableInput';
import ProductCard from '../components/ProductCard';
import initialState from '../store/initialState';
import BulkSlotter from '../components/BulkSlotter';

function ManualslottingPage({
  state,
  items,
  gridScrollHeight,
  actions,
  itemsFetching,
  bc,
  ig,
  ag,
  page,
  recordCount,
  bcs,
  search,
  range,
  detailsCard,
  loadedItems,
  isSyscoBrand,
  itemStatus,
  bulkBc,
  bulkIg,
  bulkAg,
  bulkSupcs,
  bulkLock
}) {
  gridScrollHeight = gridScrollHeight || '50vh';
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {
    if (page == null) {
      actions.loadItemDetails({ bc: bc, page: 1, itemStatus: 0 });
    }
  }, [actions, bc, page]);

  useEffect(() => {
    actions.loadTaxonomyHierarchy();
  }, [actions]);

  const allFilters = {
    bc: bc,
    ig: ig,
    ag: ag,
    range: range,
    isSyscoBrand: isSyscoBrand,
    itemStatus: itemStatus,
    page: 1,
    search: search
  };

  const onSearchChange = newValue => {
    if (newValue.keyCode === 13) {
      actions.loadItemDetails({
        ...allFilters,
        bc: 0,
        ig: null,
        ag: null,
        page: 1,
        search: newValue.target.value,
        isSyscoBrand: false,
        itemStatus: 0,
        range: null
      });
    }
  };

  const onDateRangeChange = dateRange => {
    let newRange = initialState.items.range;
    if (dateRange[0]) {
      newRange = {
        from: dateRange[0].format(dateFormat),
        to: dateRange[1].format(dateFormat)
      };
    }
    actions.loadItemDetails({ ...allFilters, range: newRange });
  };

  const onBcFilterChange = newBc => {
    actions.loadItemDetails({ ...allFilters, bc: newBc, ig: null, ag: null });
  };

  const onIgFilterChange = newIg => {
    actions.loadItemDetails({ ...allFilters, ig: newIg, ag: null });
  };

  const onAgFilterChange = newAg => {
    actions.loadItemDetails({ ...allFilters, ag: newAg });
  };

  const onIsSyscoBrandChange = newBrand => {
    actions.loadItemDetails({ ...allFilters, isSyscoBrand: newBrand.target.checked });
  };

  const onStatusChange = newStatus => {
    actions.loadItemDetails({ ...allFilters, itemStatus: newStatus });
  };

  const handlePagination = page => {
    actions.loadItemDetails({ ...allFilters, page: page });
  };

  const columns = [
    {
      title: (filters, sortOrder) => (
        <div>
          Score (%){' '}
          <Tooltip
            title="This confidence score is related to auto-slotting confidence percentage prior to manual slotting."
            className="tooltip-icon fi flaticon-question-1"
          ></Tooltip>
        </div>
      ),
      dataIndex: 'confidence',
      key: 'confidence',
      width: 95,
      render: (text, item) => <ConfidenceStateTag item={item} />,
      align: 'center'
    },
    {
      title: 'SUPC',
      dataIndex: 'supc',
      key: 'supc',
      width: 80,
      render: (supc, item) => <AnchorTag item={item} supc={supc} actions={actions} />,
      align: 'left'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'left'
    },
    {
      title: 'Business Center',
      dataIndex: 'bc',
      key: 'bc',
      width: 130,
      render: (bcId, item) => (
        <EditableInput
          selectType={'bc'}
          item={item}
          selected={bcId}
          hrchy={bcs}
          actions={actions}
          loadedItems={loadedItems}
        />
      ),
      align: 'left'
    },
    {
      title: 'Item Group',
      dataIndex: 'ig',
      key: 'ig',
      width: 130,
      render: (igId, item) => (
        <EditableInput
          selectType={'ig'}
          item={item}
          selected={igId}
          hrchy={bcs}
          actions={actions}
          loadedItems={loadedItems}
        />
      ),
      align: 'left'
    },
    {
      title: 'Attribute Group',
      dataIndex: 'ag',
      key: 'ag',
      width: 130,
      render: (agId, item) => (
        <EditableInput
          selectType={'ag'}
          item={item}
          selected={agId}
          hrchy={bcs}
          actions={actions}
          loadedItems={loadedItems}
        />
      ),
      align: 'left'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text, item) => <ItemStateTag item={item} />,
      align: 'center'
    },
    {
      title: 'Date Modified',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: 120,
      align: 'center'
    },
    {
      title: 'Update',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, item) => <ItemActions type={'APPROVEPENDING'} item={item} actions={actions} />,
      align: 'center'
    }
  ];

  let filterBc = bcs.find(element => element.id === bc);
  let filterIgs = filterBc ? filterBc.igs : [];
  let filterIg = filterIgs.find(element => element.id === ig);
  let filterAgs = filterIg ? filterIg.ags : [];

  ig = ig || null;
  ag = ag || null;
  itemStatus = itemStatus || 0;

  const defaultBulkObj = {
    supcs: bulkSupcs,
    bc: bulkBc,
    ig: bulkIg,
    ag: bulkAg
  };

  const onBulkBcChange = newBc => {
    actions.bulkPropChange({
      ...defaultBulkObj,
      ...{ bc: newBc, ig: null, ag: null }
    });
  };

  const onBulkIgChange = newIg => {
    actions.bulkPropChange({
      ...defaultBulkObj,
      ...{ ig: newIg, ag: null }
    });
  };

  const onBulkAgChange = newAg => {
    actions.bulkPropChange({
      ...defaultBulkObj,
      ...{ ag: newAg }
    });
  };

  const onBulkSupcsChange = (selectedRowKeys, selectedRows) => {
    selectedRows = selectedRows || [];
    let selectedSupcs = selectedRows.map(row => {
      return row.supc;
    });
    if (selectedSupcs.length === 0) {
      actions.bulkPropChange({
        ...defaultBulkObj,
        ...{ supcs: [], bc: null, ig: null, ag: null }
      });
    } else {
      actions.bulkPropChange({
        ...defaultBulkObj,
        ...{ supcs: [...selectedSupcs] }
      });
    }
  };

  const rowSelection = { onChange: onBulkSupcsChange };

  const agTitle = ig ? 'Attribute Group filter.' : 'Attribute Group filter. Please select an Item Group first.';

  if (itemsFetching) {
    return <AppLoader show />;
  }
  return (
    <React.Fragment>
      {/* {fetching && <AppLoader show />} */}
      <div className="bulk-inprogress" style={{ display: bulkLock ? 'flex' : 'none' }}></div>
      <div className="grid-filter-panel">
        <div className="title">
          Manual Slotting
          <Tooltip
            title="Manual slotting tool lists all the items. The users can slot them manually."
            className="tooltip-icon fi flaticon-question-1 tooltip-small"
          ></Tooltip>
        </div>
      </div>
      <div className="grid-filter-panel cuddled">
        <div className="filter-fixed">
          <input
            type="search"
            className="search right-fixed-search"
            placeholder="Search"
            onKeyUp={onSearchChange}
            defaultValue={search}
            title="Type a search phrase and press the Enter(Return) key to begin the search."
          />
        </div>
        <div className="filters">
          <div className="filter no-border">
            <label>Sysco Brand</label>
            <Checkbox checked={isSyscoBrand} onChange={onIsSyscoBrandChange} />
          </div>
          <div className="filter no-border">
            <label>Status</label>
            <Select
              style={{ width: '129px' }}
              size="small"
              defaultValue={itemStatus}
              onChange={onStatusChange}
              dropdownMatchSelectWidth={false}
            >
              <Option key={0} value={0} title="All Items">
                All
              </Option>
              <Option key={1} value={2}>
                <ItemStateTag item={{ status: 2 }} />
              </Option>
              <Option key={2} value={3}>
                <ItemStateTag item={{ status: 3 }} />
              </Option>
              <Option key={3} value={6}>
                <ItemStateTag item={{ status: 6 }} />
              </Option>
              <Option key={4} value={7}>
                <ItemStateTag item={{ status: 7 }} />
              </Option>
              <Option key={5} value={8}>
                <ItemStateTag item={{ status: 8 }} />
              </Option>
            </Select>
          </div>
          <div className="filter no-border">
            <label title="Select a date range to filter items.">
              Date
              <br />
              Modified
            </label>
            {
              <RangePicker
                style={{ width: '200px' }}
                size="small"
                onFocus={e => e.target.blur()}
                format={'MM/DD/YYYY'}
                onChange={onDateRangeChange}
                defaultValue={
                  range && initialState.items.range.from !== range.from
                    ? [moment(range.from, dateFormat), moment(range.to, dateFormat)]
                    : []
                }
              />
            }
          </div>
          <div className="filter no-border">
            <label title="Business Center search filter. This also clears the other filters.">
              Business
              <br />
              Center
            </label>
            <Select
              size="small"
              style={{ width: '110px' }}
              defaultValue={bc}
              dropdownMatchSelectWidth={false}
              onChange={onBcFilterChange}
            >
              <Option key={0} value={0} title={'All'}>
                All
              </Option>
              {bcs.map(bcsItem => (
                <Option key={bcsItem.id} value={bcsItem.id} title={bcsItem.name}>
                  {bcsItem.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter no-border">
            <label title="Item Group filter.">
              Item
              <br />
              Group
            </label>
            <Select
              style={{ width: '110px' }}
              size="small"
              defaultValue={ig}
              dropdownMatchSelectWidth={false}
              dropdownAlign={{ points: ['tr', 'br'] /* align dropdown top-right to bottom-right of input element */ }}
              onChange={onIgFilterChange}
            >
              <Option style={{ textAlign: 'right' }} value={null}>
                All
              </Option>
              {filterIgs.map(igsItem => (
                <Option style={{ textAlign: 'right' }} key={igsItem.id} value={igsItem.id} title={igsItem.name}>
                  {igsItem.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <label title={agTitle}>
              Attribute
              <br />
              Group
            </label>
            <Select
              style={{ width: '110px' }}
              size="small"
              defaultValue={ag}
              dropdownMatchSelectWidth={false}
              dropdownAlign={{ points: ['tr', 'br'] }}
              onChange={onAgFilterChange}
            >
              <Option style={{ textAlign: 'right' }} value={null}>
                All
              </Option>
              {filterAgs.map(agsItem => (
                <Option style={{ textAlign: 'right' }} key={agsItem.id} value={agsItem.id} title={agsItem.name}>
                  {agsItem.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <BulkSlotter
        bcs={bcs}
        bulkSupcs={bulkSupcs}
        bulkBc={bulkBc}
        bulkIg={bulkIg}
        bulkAg={bulkAg}
        onBulkBcChange={onBulkBcChange}
        onBulkIgChange={onBulkIgChange}
        onBulkAgChange={onBulkAgChange}
        actions={actions}
        bulkLock={bulkLock}
      />
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div className="grid-panel">
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection
              }}
              pagination={false}
              columns={columns}
              dataSource={items}
              style={{
                height: window.innerHeight - 200 + 'px',
                overflowY: 'scroll'
              }}
            />
            <Pagination
              className="grid-pagination"
              onChange={handlePagination}
              total={recordCount}
              current={page}
              defaultPageSize={30}
            />
          </div>
        )}
      />

      <ProductCard details={detailsCard} actions={actions} />
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    state: state,
    items: state.items.data,
    itemsFetching: state.items.fetching,
    page: state.items.page,
    bc: state.items.bc,
    ig: state.items.ig,
    ag: state.items.ag,
    recordCount: state.items.recordCount,
    search: state.items.search,
    range: state.items.range,
    bcs: state.taxonomy.bcs,
    detailsCard: state.detailsCard,
    loadedItems: state.loadedItems.data,
    isSyscoBrand: state.items.isSyscoBrand,
    itemStatus: state.items.itemStatus,
    bulkBc: state.bulk.bc,
    bulkIg: state.bulk.ig,
    bulkAg: state.bulk.ag,
    bulkSupcs: state.bulk.supcs,
    bulkLock: state.bulk.lock
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ManualslottingPage));

ManualslottingPage.propTypes = {};
