import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import ItemStateDetails from './ItemStateDetails';

export function ItemStateTag({ item }) {
  let status = 'NEW';
  if (item.status > 1) {
    status = 'PENDING';
  }
  if (item.status === 3) {
    status = 'AUTOAPPROVED';
  }
  if (item.status === 2) {
    status = 'AUTOSLOTTED';
  }
  if (item.status === 7) {
    status = 'PENDING2';
  }
  if (item.status === 8) {
    status = 'COMPLETED';
  }
  if (item.status === 6) {
    status = 'PENDING1';
  }
  return (
    <Tag className={ItemStateDetails[status].className}>
      <Tooltip mouseEnterDelay={1} mouseLeaveDelay={0} title={ItemStateDetails[status].tooltip}>
        {ItemStateDetails[status].description}
      </Tooltip>
    </Tag>
  );
}

export default withTranslation()(ItemStateTag);

ItemStateTag.propTypes = {
  status: PropTypes.string,
  t: PropTypes.func
};
