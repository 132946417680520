import React from 'react';

class AnchorTag extends React.Component {
  render() {
    let { item, supc, actions } = this.props;

    const onSupcClick = () => {
      actions.itemDetailsPopUp(item.stepid);
    };

    return (
      <span onClick={onSupcClick} style={{ cursor: 'pointer', color: '#33AAFF' }}>
        {supc}
      </span>
    );
  }
}

export default AnchorTag;
