import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ConfidenceStateDetails from './ConfidenceStateDetails';
import { Tooltip } from 'antd';

export function CostStateTag({ item }) {
  let status = 'BAD';
  const confident = Math.floor((item.confidence.bc + item.confidence.ig + item.confidence.ag) * (100 / 3));

  if (confident > 85) {
    status = 'SATISFACTORY';
  }
  if (confident > 95) {
    status = 'GOOD';
  }
  if (confident === 0) {
    status = 'MANUAL';
    if (item.status === 7) {
      status = 'MODIFIED';
    }
    if (item.status === 3 || item.status === 2) {
      status = 'AUTO';
    }
  }
  return (
    <div className={ConfidenceStateDetails[status].className}>
      <Tooltip
        mouseEnterDelay={1}
        mouseLeaveDelay={0}
        placement="right"
        title={ConfidenceStateDetails[status].tooltip}
        className="rating"
      >
        {confident}
      </Tooltip>
    </div>
  );
}

export default withTranslation()(CostStateTag);

CostStateTag.propTypes = {
  status: PropTypes.string,
  t: PropTypes.func
};
