import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ManualslottingPage from 'pages/Manualslotting';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import * as batchesActions from 'actions';
import AppLogo from './styles/images/app-logos/manualslotting-logo.svg';
import styles from './styles/ManualslottingApp.scss';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ actions, selectedVendor: { vendorId }, user }) {
  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  return (
    <div className="wrapper manualslotting-wrapper">
      <Router>
        <Route exact path="/suite/manualslotting" render={() => <ManualslottingPage vendorId={vendorId} />} />
      </Router>
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.user.data };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainApp);
