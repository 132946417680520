import React from 'react';
import { Select } from 'antd';

class BulkHierarchySelect extends React.Component {
  render() {
    let { selectedOption, onChangeMethod, options, firstValue } = this.props;

    const { Option } = Select;

    return (
      <div>
        {selectedOption ? (
          <Select
            style={{ width: '200px', opacity: selectedOption ? 1 : 0.5 }}
            defaultValue={selectedOption}
            dropdownMatchSelectWidth={false}
            dropdownAlign={{ points: ['bl', 'tl'] /* align dropdown top-right to bottom-right of input element */ }}
            onChange={onChangeMethod}
          >
            <Option style={{ textAlign: 'left' }} key={0} value={null}>
              {firstValue}
            </Option>
            {options.map(item => (
              <Option style={{ textAlign: 'left' }} key={item.id} value={item.id} title={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        ) : (
          <div>
            <Select
              style={{ width: '200px', opacity: selectedOption ? 1 : 0.5 }}
              defaultValue={null}
              dropdownMatchSelectWidth={false}
              dropdownAlign={{ points: ['bl', 'tl'] /* align dropdown top-right to bottom-right of input element */ }}
              onChange={onChangeMethod}
            >
              <Option style={{ textAlign: 'left' }} key={0} value={null}>
                {firstValue}
              </Option>
              {options.map(item => (
                <Option style={{ textAlign: 'left' }} key={item.id} value={item.id} title={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        )}
      </div>
    );
  }
}

export default BulkHierarchySelect;
