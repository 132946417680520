const initialState = {
  user: {
    fetching: false,
    data: {
      email: null,
      id: null,
      name: null,
      role: null,
      type: null,
      vendorList: [],
      selectedVendor: null
    },
    error: null
  },
  items: {
    fetching: false,
    data: [],
    error: null,
    page: null,
    recordCount: 0,
    bc: 0,
    ig: null,
    ag: null,
    search: '',
    range: {
      from: '0000-00-00',
      to: '9999-99-99'
    },
    isSyscoBrand: false,
    itemStatus: 3
  },
  bulk: {
    bc: null,
    ig: null,
    ag: null,
    supcs: [],
    lock: false
  },
  taxonomy: {
    bcs: []
  },
  detailsCard: {
    data: null,
    loading: false
  },
  loadedItems: {
    data: [],
    unsavedSupcs: []
  },
  editModeDropdown: ''
};

export default initialState;
