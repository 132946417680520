import React from 'react';
import { Icon } from 'antd';

class ProductCard extends React.Component {
  render() {
    let { details, actions } = this.props,
      dataSet = {
        name: '',
        description: '',
        gtin: '',
        supc: '',
        brand: '',
        pack: '',
        storage: '',
        vendor: '',
        size: '-'
      };

    const onClose = () => {
      actions.removeItemDetails();
    };

    if (details.data) {
      dataSet = details.data;
    }

    if (details.loading) {
      return (
        <div className={'product-card show'} style={{ padding: '50px' }}>
          Loading...
        </div>
      );
    }

    return (
      <div className={details.data ? 'product-card show' : 'product-card'}>
        <div className="product-header">
          <div className="header-text">Product Details</div>
          <div className="spacer"></div>
          <div className="icon fi flaticon-close" onClick={onClose}></div>
        </div>
        <div className="product-card-info">
          <div
            className="product-image"
            style={{
              backgroundImage: `url(https://image.sysco.com/image-server/product/image/${dataSet.supc}/web/1.jpg)`,
              backgroundPosition: 'center center'
            }}
          >
            <Icon type="picture" className="icon" />
          </div>
          <div className="product-name">
            {dataSet.name}
            <div className="sub">{dataSet.supc}</div>
          </div>
          <div className="product-row">
            <div className="product-data">
              <div className="header-label">BRAND</div>
              <div className="value">{dataSet.brand}</div>
            </div>
            <div className="product-data">
              <div className="header-label">SUPPLIER</div>
              <div className="value">{dataSet.vendor}</div>
            </div>
          </div>
          <div className="product-row">
            <div className="product-data">
              <div className="header-label">GTIN</div>
              <div className="value">{dataSet.gtin}</div>
            </div>
          </div>
          <div className="product-row">
            <div className="product-data">
              <div className="header-label">PACK (SIZE)</div>
              <div className="value">
                {dataSet.pack} ({dataSet.size})
              </div>
            </div>
            <div className="product-data">
              <div className="header-label">STORAGE CODE</div>
              <div className="value">{dataSet.storage}</div>
            </div>
          </div>
          <div className="product-row">
            <div className="product-data">
              <div className="header-label">DESCRIPTION</div>
              <div className="value">{dataSet.description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductCard;
