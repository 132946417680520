import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const TAXONOMY = createRequestTypes('TAXONOMY');
const HIERARCHY = createRequestTypes('HIERARCHY');
const ITEMUPDATE = createRequestTypes('ITEMUPDATE');
const ITEMSAVE = createRequestTypes('ITEMSAVE');
const ITEMSCOUNT = createRequestTypes('ITEMSCOUNT');
const ITEMSDETAILS = createRequestTypes('ITEMSDETAILS');
const ITEMSDETAILSCLOSE = createRequestTypes('ITEMSDETAILSCLOSE');
const UNSAVEDSUPCS = createRequestTypes('UNSAVEDSUPCS');
const UPDATELOADED = createRequestTypes('UPDATELOADED');
const ITEMREVERT = createRequestTypes('ITEMREVERT');
const BULKCHANGE = createRequestTypes('BULKCHANGE');

export {
  USER,
  TAXONOMY,
  ITEMSCOUNT,
  HIERARCHY,
  ITEMUPDATE,
  ITEMSAVE,
  ITEMSDETAILS,
  ITEMSDETAILSCLOSE,
  UNSAVEDSUPCS,
  UPDATELOADED,
  ITEMREVERT,
  BULKCHANGE
};
