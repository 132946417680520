import { takeLatest, put, all, takeEvery } from 'redux-saga/effects';
import { getRequest, patchRequest } from '_http';
import {
  USER,
  TAXONOMY,
  ITEMSCOUNT,
  HIERARCHY,
  ITEMUPDATE,
  ITEMSAVE,
  ITEMSDETAILS,
  UNSAVEDSUPCS,
  UPDATELOADED
} from 'actions/actionTypes';

import openNotification from 'components/Notification';
import { action } from 'reduxHelpers';
import { getCurrentUserRole } from 'Authenticator';

function* loadUserAsync() {
  try {
    const role = yield getCurrentUserRole();
    const response = yield getRequest(`/user/type/${role}`);

    yield put({ type: USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'USER LOADING ERROR'
      })
    );
  }
}

function* loadTaxonomyHierarchyAsync() {
  try {
    const response = yield getRequest(`/bcs`);
    yield put({ type: HIERARCHY.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: HIERARCHY.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'TAXONOMY LOAD ERROR'
      })
    );
  }
}

function* itemDetailsPopUpAsync({ stepId }) {
  try {
    const response = yield getRequest(`/stibo/${stepId}`),
      responseData = response.data.values,
      getValue = field => {
        let val = '';
        if (field && field.value && field.value.value) {
          val = field.value.value;
        }
        return val;
      },
      getName = (fullName, supc) => {
        return fullName.replace(` - ${supc}`, '');
      };
    let data = {
      name: getName(response.data.name, getValue(responseData.syy_itm_remotekey_a_sus)),
      description: getValue(responseData.syy_itm_a_materialunabbreviateddesc),
      gtin: getValue(responseData.syy_itm_a_gtin),
      supc: getValue(responseData.syy_itm_remotekey_a_sus),
      brand: getValue(responseData.syy_itm_a_brandname_calc),
      pack: getValue(responseData.syy_itm_a_pack),
      storage: getValue(responseData.syy_itm_a_vendorstoragecode),
      vendor: getValue(responseData.syy_itm_a_vendorname_calc),
      size: getValue(responseData.syy_itm_a_size)
    };
    yield put({ type: ITEMSDETAILS.SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: ITEMSDETAILS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'STIBO ERROR'
      })
    );
  }
}

function* loadItemsAsync({ bc, ig, ag, page, search, range, isSyscoBrand, itemStatus }) {
  try {
    if (!page) {
      page = 1;
    }
    let uri = `?page=${page}`;
    if (search) {
      uri = `${uri}&search=${encodeURIComponent(search.replace(/%/, '###'))}`;
    }
    if (ig) {
      uri = `${uri}&igId=${ig}`;
    }
    if (ag) {
      uri = `${uri}&agId=${ag}`;
    }
    if (isSyscoBrand) {
      uri = `${uri}&sysco=1`;
    }
    if (itemStatus) {
      uri = `${uri}&status=${itemStatus}`;
    }
    if (range && range.from !== '0000-00-00') {
      uri = `${uri}&range=${encodeURIComponent(range.from + '|' + range.to)}`;
    }
    const response = yield getRequest(`/items/${bc}${uri}`);
    const responsePage = {
      data: response.data.items,
      page: page,
      bc: bc,
      ig: ig,
      ag: ag,
      search: search,
      range: range,
      isSyscoBrand: isSyscoBrand,
      itemStatus: itemStatus
    };
    yield put({ type: TAXONOMY.SUCCESS, payload: responsePage });
    if (response.data.count) {
      yield put({ type: ITEMSCOUNT.SUCCESS, payload: parseInt(response.data.count) });
    }
  } catch (error) {
    yield put({ type: TAXONOMY.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ITEMS LOAD ERROR'
      })
    );
  }
}

function* updateItemSlottingAsync({ newValue, item, selectType, loadedItems }) {
  let updated = {
    supc: item.supc,
    bc: item.bc,
    ig: item.ig,
    ag: item.ag
  };
  switch (selectType) {
    case 'bc':
      updated.bc = newValue;
      updated.ig = null;
      updated.ag = null;
      break;
    case 'ig':
      updated.ig = newValue;
      updated.ag = null;
      break;
    case 'ag':
      updated.ag = newValue;
      break;
    default:
      break;
  }
  yield put({ type: ITEMUPDATE.SUCCESS, item: updated });

  const loadedItem = loadedItems.find(li => {
    return li.supc === item.supc;
  });

  const isUnSaved = loadedItem.bc !== updated.bc || loadedItem.ig !== updated.ig || loadedItem.ag !== updated.ag;
  yield put({ type: UNSAVEDSUPCS.SUCCESS, isUnSaved: isUnSaved, supc: item.supc });
}

function* saveItemSlottingAsync({ item }) {
  const supc = item.supc;
  const data = {
    status: item.status,
    bc: item.bc ? item.bc : null,
    ig: item.ig ? item.ig : null,
    ag: item.ag ? item.ag : null
  };
  try {
    const response = yield patchRequest(`/items/${supc}`, data);
    let updated = { ...item };
    if (response.status) {
      updated.status = response.data.status;
      updated.updatedat = response.data.updatedat;
    }
    yield put({ type: ITEMUPDATE.SUCCESS, item: updated });
    yield put({ type: UPDATELOADED.SUCCESS, item: response.data });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: supc + ' saved!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: TAXONOMY.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ITEMS SAVE ERROR'
      })
    );
  }
}

function* showNotificationAsync(notificationAction) {
  const { message, description, className } = notificationAction;
  yield openNotification({ message, description, className });
}

function* watchLoadItems() {
  yield takeLatest(TAXONOMY.REQUEST, loadItemsAsync);
}

function* watchLoadUser() {
  yield takeLatest(USER.REQUEST, loadUserAsync);
}

function* watchShowNotification() {
  yield takeEvery('SHOW_NOTIFICATION', showNotificationAsync);
}

function* watchLoadTaxonomyHierarchy() {
  yield takeLatest(HIERARCHY.REQUEST, loadTaxonomyHierarchyAsync);
}

function* watchUpdateItemSlotting() {
  yield takeLatest(ITEMUPDATE.REQUEST, updateItemSlottingAsync);
}

function* watchSaveItemSlotting() {
  yield takeLatest(ITEMSAVE.REQUEST, saveItemSlottingAsync);
}

function* watchItemDetailsPopUp() {
  yield takeLatest(ITEMSDETAILS.REQUEST, itemDetailsPopUpAsync);
}

export default function* rootSaga() {
  yield all([
    watchLoadUser(),
    watchShowNotification(),
    watchLoadItems(),
    watchLoadTaxonomyHierarchy(),
    watchUpdateItemSlotting(),
    watchSaveItemSlotting(),
    watchItemDetailsPopUp()
  ]);
}
