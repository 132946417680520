import React from 'react';
import { Select } from 'antd';

class EditableInput extends React.Component {
  state = {
    editmode: false
  };

  onEditButtonClick = () => {
    this.setState({
      editmode: true
    });
  };

  onValueChange = newValue => {
    let { actions, item, selectType, loadedItems } = this.props;
    if (actions) {
      actions.updateItemSlotting(item, selectType, newValue, loadedItems);
    }
  };

  exitEditMode = () => {
    this.setState({
      editmode: false
    });
  };

  revertItem = () => {
    let { actions, item, loadedItems } = this.props;
    actions.revertItem({ item: { ...item }, loadedItems: [...loadedItems] });
  };

  render() {
    const { Option } = Select;
    const emptyString = '-----------';
    let { selectType, hrchy, item, selected, loadedItems } = this.props;

    const loadedItem = loadedItems
      ? loadedItems.find(nextItem => {
          return nextItem.supc === item.supc;
        })
      : {};

    let isModified = false;

    let values = [];
    switch (selectType) {
      case 'bc':
        values = hrchy;
        if (item.bc !== loadedItem.bc) {
          isModified = true;
        }
        break;
      case 'ig':
        const bcSearchBc = hrchy.find(bc => {
          return bc.id === item.bc;
        });
        if (bcSearchBc) {
          values = bcSearchBc.igs;
        }
        if (item.ig !== loadedItem.ig) {
          isModified = true;
        }
        break;
      case 'ag':
        const bcSearch = hrchy.find(bc => {
          return bc.id === item.bc;
        });
        if (bcSearch) {
          const igSearch = bcSearch.igs.find(ig => {
            return ig.id === item.ig;
          });
          if (igSearch) {
            values = igSearch.ags;
          }
        }
        if (item.ag !== loadedItem.ag) {
          isModified = true;
        }
        break;
      default:
        values = [];
    }

    let valueText = '';
    if (selected && values.length > 0) {
      const valueTextSearch = values.find(value => value.id === selected);
      if (valueTextSearch) {
        valueText = valueTextSearch.name;
      }
    }
    if (valueText === '') {
      valueText = emptyString;
      selected = null;
    }

    return (
      <div className={this.state.editmode ? 'editable-input editmode' : 'editable-input'}>
        <div className={isModified ? 'view modified' : 'view'}>
          <div className="modified-label">
            MODIFIED
            <i
              className="icon loading fi flaticon-loading"
              role="button"
              tabIndex="0"
              style={{ cursor: 'pointer' }}
              onClick={this.revertItem}
            />
          </div>
          <div className="value" title={valueText}>
            {valueText}
          </div>
          <i
            className="icon fi flaticon-pencil"
            onClick={this.onEditButtonClick}
            onKeyDown={this.onEditButtonClick}
            role="button"
            tabIndex="0"
            style={{ cursor: 'pointer' }}
          />
        </div>

        <div className="editmode-container">
          {this.state.editmode && <div className="close-drop-down" onClick={this.exitEditMode}></div>}
          <Select
            value={selected}
            size="small"
            dropdownMatchSelectWidth={false}
            onSelect={this.exitEditMode}
            onChange={this.onValueChange}
            open={this.state.editmode}
            style={{ width: '120px' }}
          >
            <Option key={0} value={null}>
              {emptyString}
            </Option>
            {values.map(valuesItem => (
              <Option key={valuesItem.id} value={valuesItem.id} title={valuesItem.name}>
                {valuesItem.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}

export default EditableInput;
